<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
import { tologin } from "./utils/app";

import { verifyFollowStatus } from "@/api";
export default {
  created() {
    this.$eventbus.$on("verifyFollowTarget", this.verifyFollowTarget);
  },
  mounted() {
    // 监听 401 事件
    window.addEventListener(
      "AppLoginEvent",
      () => {
        if (this.$route.path !== "/login") {
          tologin();
        }
      },
      false
    );

    // 监听 403 事件
    window.addEventListener(
      "AppForbiddenEvent",
      (e) => {
        if (this.$route.path !== "/login") {
          tologin();
        }
      },
      false
    );
  },
  destroyed() {
    this.$eventbus.$off("verifyFollowTarget", this.verifyFollowTarget);
  },
  methods: {
    clear() {
      window.sessionStorage.clear();
      window.localStorage.clear();
    },
    // 认证流程控制
    // step 为空，依次验证 身份证、四要素、人脸
    // step 不为空则验证为step下标数的部分，且不跳转仅返回通过或者不通过
    async verifyFollowTarget(params) {
      const { bizNo, step = null, callback, fail } = params;
      try {
        const { data } = await verifyFollowStatus(bizNo);
        const authenticationFlag = data.authenticationFlag;
        // 无需认证
        if (!authenticationFlag)
          if (callback && typeof callback == "function") {
            callback("VERIFIED");
            return;
          }
        const keys = ["ocrResVO", "fourElementsResVO", "livingResVO"];
        const paths = ["/identity-ocr", "/identity", "/identity-portrait"];
        if (!step) {
          for (let i = 0; i < keys.length; i++) {
            const k = keys[i];
            const obj = data[k];
            if (obj && !obj.flag) {
              const path = paths[i];
              this.$router.push(`${path}?bizNo=${bizNo}`);
              return;
            }
          }
          // 如果 都校验完则跳转 home主页
          if (callback && typeof callback == "function") {
            callback("VERIFIED");
            return;
          } else {
            // this.$router.push(`/home?bizNo=${bizNo}`);
            this.$router.push(`/apply-home?bizNo=${bizNo}`);
          }
        } else {
          const k = keys[step];
          const obj = data[k];
          const pass = obj && obj.flag;
          if (callback && typeof callback == "function") {
            callback(pass ? "VERIFIED" : "UNVERIFIED");
            return;
          }
        }
      } catch (error) {
        this.$errMsg(error);
        if (fail && typeof fail == "function") {
          fail(error);
        }
      }
    }
  }
};
</script>
<style lang="scss">
@import "./styles/app.scss";
</style>

import Vue from "vue";
import Vuex from "vuex";
import persistedState from "vuex-persistedstate";

Vue.use(Vuex);
const state = {
  userInfo: {},
  bizNoQuery: null, // 从订单详情页携带进来并存储在store里的bizNo
  openId: null
};
const getters = {
  getBizNoQuery(state) {
    return state.bizNoQuery;
  },
  getUserInfo(state) {
    return state.userInfo;
  }
};
const mutations = {
  SET_USERINFO: (state, data) => {
    const d = data || {};
    state.userInfo = d;
  },
  SET_BIZNOQUERY: (state, data) => {
    state.bizNoQuery = data;
  }
};
const actions = {
  setUserInfo: ({ commit }, data) => {
    commit("SET_USERINFO", data);
  },
  setBizNoQuery: ({ commit }, data) => {
    commit("SET_BIZNOQUERY", data);
  }
};
export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {},
  plugins: [persistedState()]
});

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import filters from "@/utils/filter";
import store from "./store";
import "lib-flexible/flexible";
import VConsole from "vconsole";
Vue.use(filters);
window.checkOpen = () => {
  const open =
    localStorage.getItem("sfxh_cooperator_h5_vconsole_key") == "open";
  localStorage.setItem("sfxh_cooperator_h5_vconsole_key", "open");
  if (!open) location.reload();
};
window.checkClose = () => {
  localStorage.setItem("sfxh_cooperator_h5_vconsole_key", "close");
  location.reload();
};
// 在连接开启vconsole 只需要一次  xxx?vconsoleopen=open；避免给测试环境开启后忘关闭
function jgVConsole() {
  let urlParams = new URLSearchParams(
    window.location.href.split("?")[1]
  ).entries();
  const params = {};
  for (let param of urlParams) {
    params[param[0]] = param[1];
  }
  if (params.vconsoleopen == "open") {
    window.checkOpen();
  }
}
jgVConsole();

const open = localStorage.getItem("sfxh_cooperator_h5_vconsole_key") == "open";
if (open) new VConsole();

import Vant from "vant";
import "vant/lib/index.css";
Vue.use(Vant);
import { Toast, Dialog, Notify, ImagePreview } from "vant";

Vue.prototype.$toast = Toast;
Vue.prototype.$Dialog = Dialog;
Vue.prototype.$notify = Notify;
Vue.prototype.$ImagePreview = ImagePreview;
Vue.prototype.$eventbus = new Vue();

Vue.prototype.$errMsg = (error) => {
  console.log("error", error);
  Toast.clear(true);
  return Toast.fail(error.message || error.msg || error || "未知错误");
};
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount("#app");

import Vue from "vue";
import VueRouter from "vue-router";
import CommonStore from "@/store/index.js";

import { clearCache } from "@/utils/app";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/index"
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      title: "登录"
    },
    component: () => import("@/views/login/Index.vue")
  },
  {
    path: "/apply-home",
    name: "ApplyHome",
    meta: {
      title: "申请页"
    },
    component: () => import("@/views/home/Indexv3.vue")
  },
  {
    path: "/home",
    name: "Home",
    meta: {
      title: "申请页"
    },
    component: () => import("@/views/home/Indexv2.vue")
  },
  {
    path: "/identity-ocr",
    name: "IdentityOCR",
    meta: {
      title: "身份实名" // 身份证OCR识别认证
    },
    component: () => import("@/views/home/IdentityOCR.vue")
  },
  {
    path: "/identity-ocr-v2",
    name: "IdentityOCRv2",
    meta: {
      title: "身份实名" // 身份证OCR识别认证
    },
    component: () => import("@/views/home/IdentityOCRv2.vue")
  },
  {
    path: "/identity",
    name: "Identity",
    meta: {
      title: "身份认证" // 四要素认证
    },
    component: () => import("@/views/home/Identity.vue")
  },
  {
    path: "/identity-portrait",
    name: "IdentityPortrait",
    meta: {
      title: "活体认证" // 活体认证
    },
    component: () => import("@/views/home/IdentityPortrait.vue")
  },
  {
    path: "/identity-portrait-face",
    name: "IdentityPortraitFace",
    meta: {
      title: "活体认证" // 活体认证
    },
    component: () => import("@/views/face/Index.vue")
  },
  {
    path: "/success",
    name: "Success",
    meta: {
      // 申请页成功页
      title: "申请页"
    },
    component: () => import("@/views/home/Success.vue")
  },
  {
    path: "/index",
    name: "Index",
    meta: {
      // title: "订单详情页"
    },
    component: () => import("@/views/Index/Indexv2.vue")
  },
  {
    path: "/bankCard",
    name: "BankCard",
    meta: {
      title: "还款卡绑定"
    },
    component: () => import("@/views/bankCard/BankCard.vue")
  },
  {
    path: "/pdf",
    name: "Pdf",
    meta: {
      // title: "还款卡绑定"
    },
    component: () => import("@/views/pdf/Pdf.vue")
  },
  {
    path: "/wxOfficialAccount",
    name: "WxOfficialAccount",
    meta: {
      // title: "微信公众号绑定"
    },
    component: () => import("@/views/Index/components/WxOfficialAct.vue")
  },
  {
    path: "/credit-extension",
    name: "/CreditExtension",
    meta: {
      title: "授信"
    },
    component: () => import("@/views/home/CreditExtension.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// 没有真实登录,即接口没有token校验
// 这里后端让前端用expired来判断过期时间
// whiteList 这个是用来存放不需要进行expired校验，即没有走登录接口也可以访问的路由
// 由于这个whiteList是徐玉舒设计的，为了保证代码的稳定性，所以这里不作修改
const whiteList = [
  "/login",
  "/home",
  "/success",
  "/identity",
  "/identity-ocr",
  "/identity-portrait",
  "/pdf"
];
// const isDev = process.env.NODE_ENV === "development";

// import store from "@/store";
import { getToken } from "@/utils/app";
import { Toast } from "vant";

router.beforeEach(async (to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = "H5";
  }
  if (whiteList.includes(to.path)) {
    next();
  } else {
    const token = getToken();

    next();
    if (!token) {
      if (from.name == "Index" && from.query.bizNo) {
        CommonStore.dispatch("setBizNoQuery", from.query.bizNo);
      } else if (to.name == "Index" && to.query.bizNo) {
        CommonStore.dispatch("setBizNoQuery", to.query.bizNo);
      }

      next("/login");

      Toast.fail({
        duration: 1500,
        message: "登录信息已过期，请重新登录"
      });
      clearCache();
    } else {
      next();
    }
  }
});

export default router;
